//
// Labels
//

.form-label {
  color: var(--kt-form-label-color);
}

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  color: var(--kt-form-label-color);
}
